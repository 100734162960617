import {
  Stack,
  Text,
  Container,
  Box,
  HStack,
  Heading,
  Center,
} from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";
import { TfiInstagram } from "react-icons/tfi";
import { BsTelephoneForward } from "react-icons/bs";
import ProfileArray from "./ProfileArray";
export default function Contact({ color }) {
  const profile = ProfileArray();

  const instagram = () => {
    window.open(`${profile.instagram}`, "_blank", "noreferrer,noopener");
  };

  const whatsapp = () => {
    window.open(`${profile.whatsapp}`, "_blank", "noreferrer,noopener");
  };

  const tel = profile.tel;
  const call = profile.call;

  return (
    <>
      <Container maxW={"3xl"} id="contact">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 0 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}></Text>
            </HStack>
          </Stack>
          <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
            <Heading fontSize={"3xl"}>Onde nos encontrar?</Heading>
            <Text color={"gray.600"} fontSize={"xl"} px={4}>
              {profile.contact}
            </Text>
            <Center>
              <Stack pt={4} spacing={10}>
                <HStack style={{ cursor: "pointer" }}>
                  <TfiInstagram onClick={instagram} size={"38"} />
                  <Text
                    onClick={instagram}
                    color={`${color}.300`}
                    fontWeight={600}
                    fontSize={"lg"}
                    px={4}
                  >
                    @furinhosbabygold
                  </Text>
                </HStack>

                <HStack style={{ cursor: "pointer" }}>
                  <FaWhatsapp onClick={whatsapp} size={38} />
                  <Text
                    onClick={whatsapp}
                    color={`${color}.300`}
                    fontWeight={600}
                    fontSize={"lg"}
                    px={4}
                  >
                    Whats: (16) 99622-9955
                  </Text>
                </HStack>

                <HStack style={{ cursor: "pointer" }}>
                  <BsTelephoneForward size={38} href={`${call}`} />
                  <Text
                    color={`${color}.300`}
                    fontWeight={600}
                    fontSize={"lg"}
                    px={4}
                  >
                    Ligar: {`${tel}`}
                  </Text>
                </HStack>
              </Stack>
            </Center>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
