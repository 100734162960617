import React from "react";
import "./App.css";
import Nav from "./components/NavBar";
import Header from "./components/Hero";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  const color = "pink";

  return (
    <>
      <Nav color={color} />
      <Header color={color} />
      <About color={color} />
      <Contact color={color} />
      <Footer />
    </>
  );
}

export default App;
