import {
  Stack,
  Text,
  Container,
  Box,
  // Image
} from "@chakra-ui/react";
import ProfileArray from "./ProfileArray";

export default function About({ color }) {
  const profile = ProfileArray();
  return (
    <>
      <Container maxW={"4xl"} id="about">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 4 }}
          pb={{ base: 20, md: 12 }}> 
          <Text fontWeight={600} fontSize={"3xl"}>Quem somos</Text>

          <Text color={"gray.600"} fontSize={"xl"} px={1}>
            {profile.about}

          </Text>
 

        </Stack>
      </Container>
    </>
  );
}

